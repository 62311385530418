:root {
    --color-white: #fff;
    --color-gray: #ffffff66;
    --color-black: #000000;
    --color-black-1: #151515;
    --color-green: #6FCF97;
    --color-green-1: #80FF00;
    --color-orange: #FF8C05;
    --color-pink: #ff47b5;
    --color-pink-1: #FB6FBB;
    --color-red: #EA0909;
    --color-red-1: #d0021b;
    --bg-color-input: #232323;
    --bg-color-gradient: linear-gradient(265.48deg, #fb6fbb 0%, #9747ff 53.74%, #aa80f9 100%);
    --bg-opacity-gradient: linear-gradient(265.48deg, rgba(251, 111, 187, 0.4) 0%, rgba(151, 71, 255, 0.4) 53.74%, rgba(170, 128, 249, 0.4) 100%);
}